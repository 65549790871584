export const environment = {
    production: false,
    isMockEnabled: true, //TODO You have to switch this, TO USE THE REAL BACK-END
};

export const ADMIN_CONFIG = {

/*    apiEndpoint: 'https://apiadmin-cpa-dev.wissal-group.com/',
    wsEndpoint: 'ws://apiadmin-cpa-dev.wissal-group.com/stomp',*/

    apiEndpoint: 'https://apiadmin-cpa.ekiclik.dz/',
    wsEndpoint: 'ws://apiadmin-cpa.ekiclik.dz/stomp',

    title: 'Admin endpoint',
    clientId: 'wissalsys-ecommerce-admin',
    secret: 'XY7kmzoNzl100',
};
